import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaSave, FaEdit, FaInfoCircle } from "react-icons/fa";
import "./PracticeDetails.css";

function PracticeDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  const { template } = location.state || {};
  const {
    templateName,
    date,
    startTime,
    endTime,
    selectedPlayers = [],
    notes,
    timeIntervals = [],
  } = template || {};

  const [currentIntervals] = useState(
    timeIntervals.map((interval) => ({ ...interval }))
  );
  const [practiceName, setPracticeName] = useState(templateName || "");
  const [practiceDate, setPracticeDate] = useState(date || "");
  const [practiceStartTime] = useState(startTime || "");
  const [practiceEndTime] = useState(endTime || "");
  const [cellDrills, setCellDrills] = useState({});
  const [cellPlays, setCellPlays] = useState({});
  const [cellNotes, setCellNotes] = useState({});
  const [showDrillModal, setShowDrillModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentDrillCell, setCurrentDrillCell] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [currentDetails, setCurrentDetails] = useState(null);
  const [showPlayModal, setShowPlayModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [localDrillPositions, setLocalDrillPositions] = useState([]);
  const [localPlayPositions, setLocalPlayPositions] = useState([]);
  const [localNotePositions, setLocalNotePositions] = useState([]);
  const [activeForm, setActiveForm] = useState(null); // Track which form is active


  // Format the date to MM-DD-YYYY
  useEffect(() => {
    if (practiceDate) {
      const formattedDate = new Date(practiceDate);
      const formatted = `${(formattedDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${formattedDate
        .getDate()
        .toString()
        .padStart(2, "0")}-${formattedDate.getFullYear()}`;
      setPracticeDate(formatted);
    }
  }, [practiceDate]);

  const handlePositionChange = (position, setPositions, positions) => {
    if (position === "All Players") {
      // If "All Players" is clicked, check if all positions are selected
      const allSelected = positions.length === selectedPlayers.length;

      // If all players are selected, clear all selections; else, select all players
      setPositions(allSelected ? [] : selectedPlayers);
    } else {
      setPositions((prev) => {
        // Toggle the individual position selection
        const updatedPositions = prev.includes(position)
          ? prev.filter((p) => p !== position)
          : [...prev, position];

        // Check if "All Players" should be added based on the number of selected positions
        const isAllSelected =
          updatedPositions.length === selectedPlayers.length &&
          !updatedPositions.includes("All Players");

        // If all players are selected, add "All Players" to the list, otherwise remove it
        return isAllSelected
          ? [...updatedPositions, "All Players"]
          : updatedPositions.filter((p) => p !== "All Players");
      });
    }
  };

  const handleSave = () => {
    if (activeForm === "drill") {
      const drillDetails = {
        name: document.querySelector('input[name="drillName"]').value,
        details: document.querySelector('textarea[name="drillDetails"]').value,
        tag: document.querySelector('input[name="drillTag"]').value,
        link: document.querySelector('input[name="drillLink"]').value,
        notes: document.querySelector('textarea[name="drillNotes"]').value,
        positions: localDrillPositions,
      };
      handleAddDrill(drillDetails);
    } else if (activeForm === "play") {
      const playDetails = {
        playName: document.querySelector('input[name="playName"]').value,
        playDescription: document.querySelector('textarea[name="playDescription"]').value,
        positions: localPlayPositions,
      };
      handleAddPlay(playDetails);
    } else if (activeForm === "note") {
      const noteDetails = {
        noteTitle: document.querySelector('input[name="noteTitle"]').value,
        noteContent: document.querySelector('textarea[name="noteContent"]').value,
        positions: localNotePositions,
      };
      handleAddNote(noteDetails);
    }
    setShowEditModal(false);
  };

  // Save practice details to localStorage
  const handleReadyToPractice = () => {
    const practiceDetails = {
      practiceName,
      practiceDate,
      startTime: practiceStartTime,
      endTime: practiceEndTime,
      notes,
      selectedPlayers,
      timeIntervals: currentIntervals,
      drills: cellDrills,
      plays: cellPlays,
      notes: cellNotes,
    };

    localStorage.setItem("upcomingPractice", JSON.stringify(practiceDetails));
    alert(`Practice on ${practiceDate} added to Upcoming Practices.`);
    navigate("/practice-plan/");
  };

  // Open drill modal for adding a drill
  const openDrillModal = (time, player) => {
    setCurrentDrillCell({ time, player });
    setShowDrillModal(true);
  };

  // Add a drill to the specific cell
  const handleAddDrill = (drillDetails) => {
    if (currentDrillCell) {
      const { time } = currentDrillCell;
      const selectedPositions = drillDetails.positions || [];
      const updatedCellDrills = { ...cellDrills };

      if (
        selectedPositions.includes("All Players") ||
        selectedPositions.length === 0
      ) {
        selectedPlayers.forEach((player) => {
          const cellKey = `${time}-${player}`;
          updatedCellDrills[cellKey] = [
            ...(updatedCellDrills[cellKey] || []),
            { ...drillDetails, positions: selectedPlayers }, //Save positions with the drill
          ];
        });
      } else {
        selectedPositions.forEach((player) => {
          const cellKey = `${time}-${player}`;
          updatedCellDrills[cellKey] = [
            ...(updatedCellDrills[cellKey] || []),
            { ...drillDetails, positions: selectedPositions },
          ];
        });
      }

      setCellDrills(updatedCellDrills);
      setShowEditModal(false);
      setCurrentDrillCell(null);
    }
  };

  const handleViewDetails = (time, player) => {
    const cellKey = `${time}-${player}`;
    const details = {
      drills: cellDrills[cellKey] || [],
      plays: cellPlays[cellKey] || [],
      notes: cellNotes[cellKey] || [],
    };
    setCurrentDetails(details);
    setShowDetailsModal(true);
  };

  const handleAddPlay = (playDetails) => {
    if (currentDrillCell) {
      const { time } = currentDrillCell;
      const selectedPositions = playDetails.positions || [];
      const updatedCellPlays = { ...cellPlays };

      if (
        selectedPositions.includes("All Players") ||
        selectedPositions.length === 0
      ) {
        selectedPlayers.forEach((player) => {
          const cellKey = `${time}-${player}`;
          updatedCellPlays[cellKey] = [
            ...(updatedCellPlays[cellKey] || []),
            { ...playDetails, positions: selectedPlayers },
          ];
        });
      } else {
        selectedPositions.forEach((player) => {
          const cellKey = `${time}-${player}`;
          updatedCellPlays[cellKey] = [
            ...(updatedCellPlays[cellKey] || []),
            { ...playDetails, positions: selectedPositions },
          ];
        });
      }

      setCellPlays(updatedCellPlays);
      setShowPlayModal(false);
      setCurrentDrillCell(null);
    }
  };

  const handleAddNote = (noteDetails) => {
    if (currentDrillCell) {
      const { time } = currentDrillCell;
      const selectedPositions = noteDetails.positions || [];
      const updatedCellNotes = { ...cellNotes };

      if (
        selectedPositions.includes("All Players") ||
        selectedPositions.length === 0
      ) {
        selectedPlayers.forEach((player) => {
          const cellKey = `${time}-${player}`;
          updatedCellNotes[cellKey] = [
            ...(updatedCellNotes[cellKey] || []),
            { ...noteDetails, positions: selectedPlayers },
          ];
        });
      } else {
        selectedPositions.forEach((player) => {
          const cellKey = `${time}-${player}`;
          updatedCellNotes[cellKey] = [
            ...(updatedCellNotes[cellKey] || []),
            { ...noteDetails, positions: selectedPositions },
          ];
        });
      }

      setCellNotes(updatedCellNotes);
      setShowNoteModal(false);
      setCurrentDrillCell(null);
    }
  };

  const openEditModal = (time, player) => {
    console.log("Open Edit Modal for time:", time, "and player:", player);
    setCurrentDrillCell({ time, player });
    setShowEditModal(true);
  };

  // Merge vertical cells in the table for better UI
  const mergeVerticalCells = (intervals, player) => {
    const merged = [];
    let previous = null;

    intervals.forEach((interval, idx) => {
      const assignment = interval.assignments[player];
      if (
        previous &&
        assignment &&
        previous.assignment &&
        previous.assignment.activity === assignment.activity &&
        previous.assignment.color === assignment.color
      ) {
        previous.rowSpan += 1;
      } else {
        if (previous) merged.push(previous);
        previous = { index: idx, rowSpan: 1, assignment };
      }
    });

    if (previous) merged.push(previous);

    return merged;
  };

  const verticalMerges = selectedPlayers.reduce((acc, player) => {
    acc[player] = mergeVerticalCells(currentIntervals, player);
    return acc;
  }, {});

  return (
    <div className="practice-details">
      <h1>Practice Plan Details</h1>

      <div className="details-summary">
        <p>
          <strong>Practice Name: </strong>
          {practiceName}
        </p>
        <p>
          <strong>Date: </strong>
          {practiceDate}
        </p>
        <p>
          <strong>Start Time:</strong> {practiceStartTime}
        </p>
        <p>
          <strong>End Time:</strong> {practiceEndTime}
        </p>
        <p>
          <strong>Notes:</strong> {notes || "No notes provided."}
        </p>
      </div>

      <h3>Practice Schedule</h3>
      {currentIntervals && currentIntervals.length > 0 ? (
        <table className="time-interval-table">
          <thead>
            <tr>
              <th>Time</th>
              {selectedPlayers.map((player, idx) => (
                <th key={idx}>{player}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentIntervals.map((interval, rowIndex) => (
              <tr key={rowIndex}>
                <td>{interval.time}</td>
                {selectedPlayers.map((player, playerIdx) => {
                  const mergeData = verticalMerges[player].find(
                    (merge) => merge.index === rowIndex
                  );

                  if (!mergeData || mergeData.index !== rowIndex) return null;

                  return (
                    <td
                      key={playerIdx}
                      rowSpan={mergeData.rowSpan}
                      style={{
                        backgroundColor: "#fff",
                        color:
                          mergeData.assignment?.color === "#000000"
                            ? "#fff"
                            : "#000",
                        textAlign: "center",
                        position: "relative",
                        padding: "10px",
                      }}
                    >
                      {mergeData.assignment ? ( // Ensure assignment exists before rendering actions
                        <div className="capsule-container">
                          <div
                            className="capsule-content"
                            style={{
                              backgroundColor: mergeData.assignment.color, // Apply assignment color to the capsule
                              padding: "5px 10px",
                              borderRadius: "20px",
                              color:
                                mergeData.assignment?.color === "#000000"
                                  ? "#fff"
                                  : "#000",
                              display: "inline-block",
                            }}
                          >
                            <div className="activity-name">
                              {mergeData.assignment.activity}
                            </div>
                            <div className="capsule-actions">
                              <p
                                className="action-icon"
                                onClick={() =>
                                  openEditModal(interval.time, player)
                                }
                                title="Edit Details"
                              >
                                <FaEdit /> Edit
                              </p>

                              {(cellDrills[`${interval.time}-${player}`]
                                ?.length > 0 ||
                                cellPlays[`${interval.time}-${player}`]
                                  ?.length > 0 ||
                                cellNotes[`${interval.time}-${player}`]
                                  ?.length > 0) && (
                                <p
                                  className="view-details-btn"
                                  onClick={() =>
                                    handleViewDetails(interval.time, player)
                                  }
                                >
                                  <FaInfoCircle /> View Details
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}{" "}
                      {/* Don't render anything if no assignment exists */}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No schedule data available.</p>
      )}

      <div className="backready">
        <button
          onClick={() => navigate("/practice-plan")}
          className="back-button"
        >
          Back to Practice Plans
        </button>
        <button className="ready-button" onClick={handleReadyToPractice}>
          <FaSave />
          &nbsp; Save
        </button>
      </div>

      {/* pop up edit window */}
      {showEditModal && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="edit-forms">
              <details
                open={activeForm === "drill"}
                onToggle={(e) => setActiveForm(e.target.open ? "drill" : null)}
              >
                <summary>Add Drill</summary>
                <form>
                  <label>
                    Drill Name:
                    <input type="text" name="drillName" required />
                  </label>
                  <label>
                    Drill Details:
                    <textarea name="drillDetails" required></textarea>
                  </label>
                  <label>
                    Drill Tag:
                    <input type="text" name="drillTag" />
                  </label>
                  <label>
                    Drill Link:
                    <input type="url" name="drillLink" />
                  </label>
                  <label>
                    Coaches Notes:
                    <textarea name="drillNotes"></textarea>
                  </label>
                  <label>
                    Select Positional Groups:
                    <div className="checkbox-group">
                      {["All Players", ...selectedPlayers].map((player) => (
                        <label
                          key={player}
                          className={`checkbox-label-horizontal ${
                            localDrillPositions.includes(player)
                              ? "selected"
                              : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            name="position"
                            value={player}
                            checked={localDrillPositions.includes(player)}
                            onChange={() =>
                              handlePositionChange(
                                player,
                                setLocalDrillPositions,
                                localDrillPositions
                              )
                            }
                            hidden
                          />
                          <span>{player}</span>
                        </label>
                      ))}
                    </div>
                  </label>
                </form>
              </details>

              <details
                open={activeForm === "play"}
                onToggle={(e) => setActiveForm(e.target.open ? "play" : null)}
              >
                <summary>Add Play</summary>
                <form>
                  <label>
                    Play Name:
                    <input type="text" name="playName" required />
                  </label>
                  <label>
                    Play Description:
                    <textarea name="playDescription" required></textarea>
                  </label>
                  <label>
                    Select Positional Groups:
                    <div className="checkbox-group">
                      {["All Players", ...selectedPlayers].map((player) => (
                        <label
                          key={player}
                          className={`checkbox-label-horizontal ${
                            localPlayPositions.includes(player)
                              ? "selected"
                              : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            value={player}
                            checked={localPlayPositions.includes(player)}
                            onChange={() =>
                              handlePositionChange(
                                player,
                                setLocalPlayPositions,
                                localPlayPositions
                              )
                            }
                            hidden
                          />
                          <span>{player}</span>
                        </label>
                      ))}
                    </div>
                  </label>
                </form>
              </details>

              <details
                open={activeForm === "note"}
                onToggle={(e) => setActiveForm(e.target.open ? "note" : null)}
              >
                <summary>Add Note</summary>
                <form>
                  <label>
                    Note Title:
                    <input type="text" name="noteTitle" required />
                  </label>
                  <label>
                    Note Content:
                    <textarea name="noteContent" required></textarea>
                  </label>
                  <label>
                    Select Positional Groups:
                    <div className="checkbox-group">
                      {["All Players", ...selectedPlayers].map((player) => (
                        <label
                          key={player}
                          className={`checkbox-label-horizontal ${
                            localNotePositions.includes(player)
                              ? "selected"
                              : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            value={player}
                            checked={localNotePositions.includes(player)}
                            onChange={() =>
                              handlePositionChange(
                                player,
                                setLocalNotePositions,
                                localNotePositions
                              )
                            }
                            hidden
                          />
                          <span>{player}</span>
                        </label>
                      ))}
                    </div>
                  </label>
                </form>
              </details>
            </div>
            <button onClick={handleSave}>Save</button>
            <button onClick={() => setShowEditModal(false)}>Close</button>
          </div>
        </div>
      )}

      {/* To show the pop up detail window */}
      {showDetailsModal && currentDetails && (
        <div className="modal-overlay">
          <div className="modal">
            <h3 className="modal-title">Activity Details</h3>
            <div className="details-content scrollable-content">
              {currentDetails.drills.length > 0 && (
                <div className="detail-card">
                  <h4>Drills</h4>
                  {currentDetails.drills.map((drill, index) => (
                    <div key={`drill-${index}`}>
                      <h5>Drill Name: {drill.name}</h5>
                      <p>Drill Details: {drill.details}</p>
                      <p>Drill Tag: {drill.tag}</p>
                      <p>Drill Link: {drill.link}</p>
                      <p>Coaches Notes: {drill.notes}</p>
                    </div>
                  ))}
                </div>
              )}

              {currentDetails.plays.length > 0 && (
                <div className="detail-card">
                  <h4>Plays</h4>
                  {currentDetails.plays.map((play, index) => (
                    <div key={`play-${index}`}>
                      <h5>Play Name: {play.playName}</h5>
                      <p>Play Description: {play.playDescription}</p>
                    </div>
                  ))}
                </div>
              )}

              {currentDetails.notes.length > 0 && (
                <div className="detail-card">
                  <h4>Notes</h4>
                  {currentDetails.notes.map((note, index) => (
                    <div key={`note-${index}`}>
                      <h5>Note Title: {note.noteTitle}</h5>
                      <p>{note.noteContent}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button
              className="close-btn"
              onClick={() => setShowDetailsModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PracticeDetails;
