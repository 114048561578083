import React, { useState } from "react";
import "./PracticeScheduleForm.css";
import { FaArrowRight } from "react-icons/fa";

const convertTo24Hour = (time12) => {
  if (!time12) return "";
  const [time, modifier] = time12.split(" ");
  let [hours, minutes] = time.split(":");
  hours = Number.parseInt(hours, 10);

  if (modifier === "PM" && hours < 12) {
    hours += 12;
  } else if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes}`;
};

const convertTo12Hour = (time24) => {
  if (!time24) return "";
  let [hours, minutes] = time24.split(":");
  hours = Number.parseInt(hours);
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format
  return `${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;
};

function PracticeScheduleForm({ onFormSubmit }) {
  const [formData, setFormData] = useState({
    templateName: "",
    date: "",
    startTime: "",
    endTime: "",
    selectedPlayers: [], // Selected players will form columns
    notes: "",
  });

  const [playerPositions, setPlayerPositions] = useState([
    "All Positional Groups",
    "QB",
    "RB",
    "WR",
    "OL",
    "DL",
    "LB",
    "DB",
  ]);
  const [newPlayer, setNewPlayer] = useState("");
  const [tooltip, setTooltip] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const showTooltip = (message, type) => {
    setTooltip({ message, type, visible: true });
    setTimeout(() => setTooltip({ ...tooltip, visible: false }), 2000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePlayerSelect = (player) => {
    if (player === "All Positional Groups") {
      const allSelected = formData.selectedPlayers.includes(
        "All Positional Groups"
      );
      setFormData((prev) => ({
        ...prev,
        selectedPlayers: allSelected
          ? []
          : playerPositions.filter(
              (position) => position !== "All Positional Groups"
            ),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        selectedPlayers: prev.selectedPlayers.includes(player)
          ? prev.selectedPlayers.filter((p) => p !== player)
          : [...prev.selectedPlayers, player],
      }));
    }
  };

  const handleAddPlayer = () => {
    if (!newPlayer.trim()) {
      showTooltip("Position name cannot be empty.", "error");
      return;
    }
    if (playerPositions.includes(newPlayer.trim())) {
      showTooltip("This position already exists.", "error");
      return;
    }
    setPlayerPositions((prev) => [...prev, newPlayer.trim()]);
    setNewPlayer("");
    showTooltip("Player position added successfully.", "success");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const [startHour, startMinute] = formData.startTime.split(":").map(Number);
    const [endHour, endMinute] = formData.endTime.split(":").map(Number);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Normalize to start of the day

    const selectedDate = new Date(formData.date);
    selectedDate.setHours(0, 0, 0, 0); // Normalize to start of the day

    if (selectedDate < currentDate) {
      console.log("Selected date is in the past.");
    }

    if (
      !formData.templateName ||
      !formData.date ||
      !formData.startTime ||
      !formData.endTime
    ) {
      showTooltip("Please fill out all required fields.", "error");
      return;
    }

    // handle time
    
    
    //end handle time
    if (
      selectedDate.setHours(startHour, startMinute, 0, 0) <
        currentDate.getTime() &&
      selectedDate.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0)
    ) {
      showTooltip("Start time cannot be in the past.", "error");
      return;
    }

    if (
      startHour > endHour ||
      (startHour === endHour && startMinute >= endMinute)
    ) {
      showTooltip("End time must be after the start time.", "error");
      return;
    }

    if (formData.selectedPlayers.length === 0) {
      showTooltip("Please select at least one player position.", "error");
      return;
    }

    onFormSubmit(formData);
    showTooltip("Practice schedule submitted successfully.", "success");
  };

  return (
    <div className="practice-template-form">
      <h3>Create Practice Schedule</h3>
      {tooltip.visible && (
        <div className={`tooltip ${tooltip.type}`}>{tooltip.message}</div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-grid">
          <div className="form-group">
            <label>Practice Name</label>
            <input
              type="text"
              name="templateName"
              placeholder="Enter practice name"
              value={formData.templateName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Date</label>
            <input
              type="text"
              name="date"
              placeholder="YYYY-MM-DD"
              value={formData.date}
              onChange={handleChange}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
            />
          </div>
          <div className="form-group">
            <label>Start Time</label>
            <input
              type="text"
              name="startTime"
              placeholder="HH:MM"
              value={
                formData.startTime ? convertTo12Hour(formData.startTime) : ""
              }
              onChange={(e) => {
                const time24 = convertTo24Hour(e.target.value);
                setFormData({ ...formData, startTime: time24 });
              }}
              onFocus={(e) => (e.target.type = "time")}
              onBlur={(e) => {
                e.target.type = "text";
                if (formData.startTime) {
                  e.target.value = convertTo12Hour(formData.startTime);
                }
              }}
            />
          </div>
          <div className="form-group">
            <label>End Time</label>
            <input
              type="text"
              name="endTime"
              placeholder="HH:MM"
              value={formData.endTime ? convertTo12Hour(formData.endTime) : ""}
              onChange={(e) => {
                const time24 = convertTo24Hour(e.target.value);
                setFormData({ ...formData, endTime: time24 });
              }}
              onFocus={(e) => (e.target.type = "time")}
              onBlur={(e) => {
                e.target.type = "text";
                if (formData.endTime) {
                  e.target.value = convertTo12Hour(formData.endTime);
                }
              }}
            />
          </div>
          <div className="form-group full-width">
            <label>Additional Notes</label>
            <textarea
              name="notes"
              placeholder="Enter any additional notes"
              value={formData.notes}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="form-group full-width">
            <label>Player Positions</label>
            <div className="player-selection">
              {playerPositions.map((player) => (
                <div
                  key={player}
                  className={`player-item ${
                    formData.selectedPlayers.includes(player) ? "selected" : ""
                  }`}
                  onClick={() => handlePlayerSelect(player)}
                >
                  {player}
                </div>
              ))}
            </div>
            <div className="add-player">
              <input
                type="text"
                placeholder="Add new positional group"
                value={newPlayer}
                onChange={(e) => setNewPlayer(e.target.value)}
              />
              <button type="button" onClick={handleAddPlayer}>
                Add
              </button>
            </div>
          </div>
        </div>
        <div class="form-actions">
          <button type="submit" className="submit-button">
            Next <FaArrowRight />
          </button>
        </div>
      </form>
    </div>
  );
}

export default PracticeScheduleForm;
