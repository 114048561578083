import React, { useState } from "react";
import "./PracticeScheduleReview.css";

function PracticeScheduleReview({
  formData,
  onCreateTemplate,
  onGoBack,
  onUpdateSelectedPlayers,
}) {
  const { templateName, date, startTime, endTime, selectedPlayers, notes } =
    formData;

  const [activities, setActivities] = useState([
    { name: "All Players Drill", type: "All", color: "#89cff0" },
    { name: "Offensive Drill", type: "Offense", color: "#f4c430" },
    { name: "Defensive Drill", type: "Defense", color: "#000000" },
  ]);

  const [tooltip, setTooltip] = useState({ message: "", type: "", visible: false });
  const [draggedActivity, setDraggedActivity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [practicesSaved, setPracticesSaved] = useState(false);
  const [newActivity, setNewActivity] = useState({
    name: "",
    type: "",
    startTime: "",
    endTime: "",
    positions: [],
  });

  const generateTimeIntervals = (start, end) => {
    const intervals = [];
    let current = new Date(`1970-01-01T${start}:00`);
    const endTime = new Date(`1970-01-01T${end}:00`);

    while (current <= endTime) {
      intervals.push({
        time: current.toTimeString().slice(0, 5),
        assignments: selectedPlayers.reduce((acc, player) => {
          acc[player] = null;
          return acc;
        }, {}),
      });
      current.setMinutes(current.getMinutes() + 5);
    }
    return intervals;
  };

  const [timeIntervals, setTimeIntervals] = useState(
    generateTimeIntervals(startTime, endTime)
  );

  const handleDragStart = (activity) => {
    setDraggedActivity(activity);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (time, player) => {
    if (!draggedActivity) return;

    setTimeIntervals((prev) =>
      prev.map((interval) =>
        interval.time === time
          ? {
              ...interval,
              assignments: {
                ...interval.assignments,
                [player]: {
                  activity: draggedActivity.name,
                  color: draggedActivity.color,
                },
              },
            }
          : interval
      )
    );
    setDraggedActivity(null);
  };

  const handleAddActivityClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewActivity({ name: "", type: "", startTime: "", endTime: "", positions: [] });
  };

  const handlePositionChange = (position) => {
    if (position === "All Players") {
      const allSelected = newActivity.positions.includes("All Players");
      setNewActivity((prev) => ({
        ...prev,
        positions: allSelected ? [] : selectedPlayers,
      }));
    } else {
      setNewActivity((prev) => {
        const updatedPositions = prev.positions.includes(position)
          ? prev.positions.filter((p) => p !== position)
          : [...prev.positions, position];
        const isAllSelected =
          updatedPositions.length === selectedPlayers.length &&
          !updatedPositions.includes("All Players");

        return {
          ...prev,
          positions: isAllSelected
            ? [...updatedPositions, "All Players"]
            : updatedPositions.filter((p) => p !== "All Players"),
        };
      });
    }
  };

  const filterAvailableTimes = () => {
    if (newActivity.positions.length === 0) return [];
    const availableTimes = timeIntervals.filter((interval) =>
      newActivity.positions.every(
        (position) => !interval.assignments[position]?.activity
      )
    );
    return availableTimes;
  };

  const availableTimes = filterAvailableTimes();

  const handleSaveActivity = () => {
    if (
      !newActivity.name ||
      !newActivity.type ||
      !newActivity.startTime ||
      !newActivity.endTime ||
      newActivity.positions.length === 0
    ) {
      setTooltip({ message: "All fields are required.", type: "error", visible: true });
      setTimeout(() => setTooltip({ visible: false }), 2000);
      return;
    }

    const newColor =
      newActivity.type === "Offense"
        ? "#f4c430"
        : newActivity.type === "Defense"
        ? "#000000"
        : "#89cff0";

    const endTimeIndex = timeIntervals.findIndex(
      (interval) => interval.time === newActivity.endTime
    );
    const adjustedEndTime = endTimeIndex > 0 ? timeIntervals[endTimeIndex - 1].time : newActivity.endTime;

    setTimeIntervals((prev) =>
      prev.map((interval) => {
        if (
          interval.time >= newActivity.startTime &&
          interval.time <= adjustedEndTime
        ) {
          const newAssignments = { ...interval.assignments };
          newActivity.positions.forEach((position) => {
            newAssignments[position] = {
              activity: newActivity.name,
              color: newColor,
            };
          });
          return { ...interval, assignments: newAssignments };
        }
        return interval;
      })
    );

    setActivities((prev) => [
      ...prev,
      { name: newActivity.name, type: newActivity.type, color: newColor },
    ]);

    setIsModalOpen(false);
    setNewActivity({ name: "", type: "", startTime: "", endTime: "", positions: [] });
    setTooltip({ message: "Activity added successfully.", type: "success", visible: true });
    setTimeout(() => setTooltip({ visible: false }), 2000);

    setPracticesSaved(true);
  };

  //This will handle the save and continue activity
  const handleSaveAndContinue = () => {
    if (
      !newActivity.name ||
      !newActivity.type ||
      !newActivity.startTime ||
      !newActivity.endTime ||
      newActivity.positions.length === 0
    ) {
      setTooltip({ message: "All fields are required.", type: "error", visible: true });
      setTimeout(() => setTooltip({ visible: false }), 2000);
      return;
    }
  
    // Check for existing activities in the selected time period
    const startTimeIndex = timeIntervals.findIndex(interval => interval.time === newActivity.startTime);
    const endTimeIndex = timeIntervals.findIndex(interval => interval.time === newActivity.endTime);
    const adjustedEndTime = endTimeIndex > 0 ? timeIntervals[endTimeIndex - 1].time : newActivity.endTime;
  
    const hasOverlap = timeIntervals.slice(startTimeIndex, endTimeIndex).some(interval => 
      Object.values(interval.assignments).some(assignment => 
        newActivity.positions.includes(Object.keys(interval.assignments).find(key => interval.assignments[key] === assignment))
      )
    );
  
    if (hasOverlap) {
      console.warn("Overlapping activity detected");
      const confirmOverwrite = window.confirm("There is an existing activity in this time period. Do you want to overwrite?");
      if (!confirmOverwrite) {
        setTooltip({ message: "Activity not saved. Please adjust the time or positions.", type: "info", visible: true });
        setTimeout(() => setTooltip({ visible: false }), 2000);
        return;
      }
    }
  
    const newColor =
      newActivity.type === "Offense"
        ? "#f4c430"
        : newActivity.type === "Defense"
        ? "#000000"
        : "#89cff0";
  
    setTimeIntervals((prev) =>
      prev.map((interval) => {
        if (
          interval.time >= newActivity.startTime &&
          interval.time <= adjustedEndTime
        ) {
          const newAssignments = { ...interval.assignments };
          newActivity.positions.forEach((position) => {
            newAssignments[position] = {
              activity: newActivity.name,
              color: newColor,
            };
          });
          return { ...interval, assignments: newAssignments };
        }
        return interval;
      })
    );
  
    setActivities((prev) => [
      ...prev,
      { name: newActivity.name, type: newActivity.type, color: newColor },
    ]);
  
    // Reset the form for the next activity
    setNewActivity({ name: "", type: "", startTime: "", endTime: "", positions: [] });
  
    setTooltip({ 
      message: hasOverlap 
        ? "Activity added successfully, overwriting existing activities. You can add another." 
        : "Activity added successfully. You can add another.", 
      type: "success", 
      visible: true 
    });
    setTimeout(() => setTooltip({ visible: false }), 2000);
  
    setPracticesSaved(false);
  };
  return (
    <div className="practice-schedule-review">
      <h3>Practice Schedule Review</h3>
      {tooltip.visible && (
        <div className={`tooltip ${tooltip.type}`}>{tooltip.message}</div>
      )}
      <div className="review-summary">
        <p>
          <strong>Practice Name:</strong> {templateName}
        </p>
        <p>
          <strong>Date:</strong> {date}
        </p>
        <p>
          <strong>Start Time:</strong> {startTime}
        </p>
        <p>
          <strong>End Time:</strong> {endTime}
        </p>
        <p>
          <strong>Notes:</strong> {notes}
        </p>
      </div>

      <div className="activities-section">
        <h4>Frequently Used Practice Periods</h4>
        <div className="activities-list">
          {activities.map((activity, index) => (
            <div
              key={index}
              className="draggable-activity"
              style={{
                backgroundColor: activity.color,
                color: activity.color === "#000000" ? "#ffffff" : "#000000",
              }}
              draggable
              onDragStart={() => handleDragStart(activity)}
            >
              {activity.name}
            </div>
          ))}
        </div>
        <button className="add-activity-button" onClick={handleAddActivityClick}>
          + Add Practice Period
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="close-modal-button" onClick={handleModalClose}>
              &times;
            </button>
            <h4>Add Practice Periods</h4>
            <label>
              Practice Period Name:
              <input
                type="text"
                value={newActivity.name}
                onChange={(e) =>
                  setNewActivity((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </label>
            <label>
              Group Focus:
              <div className="radio-group">
                {["Offense", "Defense", "All"].map((type) => (
                  <label
                    key={type}
                    className={`radio-label ${
                      newActivity.type === type ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="type"
                      value={type}
                      onChange={(e) =>
                        setNewActivity((prev) => ({
                          ...prev,
                          type: e.target.value,
                        }))
                      }
                      hidden
                    />
                    <span>{type}</span>
                  </label>
                ))}
              </div>
            </label>
            <label>
              Select Positional Groups:
              <div className="checkbox-group">
                {["All Players", ...selectedPlayers].map((player) => (
                  <label
                    key={player}
                    className={`checkbox-label-horizontal ${
                      newActivity.positions.includes(player) ? "selected" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      value={player}
                      checked={newActivity.positions.includes(player)}
                      onChange={() => handlePositionChange(player)}
                      hidden
                    />
                    <span>{player}</span>
                  </label>
                ))}
              </div>
            </label>
            <label>
              Start Time:
              <select
                value={newActivity.startTime}
                onChange={(e) =>
                  setNewActivity((prev) => ({
                    ...prev,
                    startTime: e.target.value,
                  }))
                }
              >
                <option value="">Select Start Time</option>
                {availableTimes.map((interval) => (
                  <option key={interval.time} value={interval.time}>
                    {interval.time}
                  </option>
                ))}
              </select>
            </label>
            <label>
              End Time:
              <select
                value={newActivity.endTime}
                onChange={(e) =>
                  setNewActivity((prev) => ({
                    ...prev,
                    endTime: e.target.value,
                  }))
                }
              >
                <option value="">Select End Time</option>
                {availableTimes.map((interval) => (
                  <option key={interval.time} value={interval.time}>
                    {interval.time}
                  </option>
                ))}
              </select>
            </label>
            <button className="save-button" onClick={handleSaveActivity}>
              Save
            </button>
            <button className="save-button" onClick={handleSaveAndContinue}>
              Save and Continue
            </button>
          </div>
        </div>
      )}

      <div className="time-interval-table">
        <h4>Practice Schedule</h4>
        <table>
          <thead>
            <tr>
              <th>Time</th>
              {selectedPlayers.map((player) => (
                <th key={player}>{player}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timeIntervals.map((interval, rowIndex) => (
              <tr key={interval.time}>
                <td>{interval.time}</td>
                {selectedPlayers.map((player, playerIndex) => {
                  const isAffected =
                    practicesSaved &&
                    interval.assignments[player]?.activity &&
                    timeIntervals.some(
                      (timeInterval) =>
                        timeInterval.time >= interval.time &&
                        timeInterval.assignments[player]?.activity ===
                          interval.assignments[player]?.activity
                    );

                  if (isAffected) {
                    const isStartOfGroup =
                      rowIndex === 0 ||
                      timeIntervals[rowIndex - 1].assignments[player]?.activity !==
                        interval.assignments[player]?.activity;

                    if (isStartOfGroup) {
                      let rowSpan = 1;
                      for (
                        let i = rowIndex + 1;
                        i < timeIntervals.length &&
                        timeIntervals[i].assignments[player]?.activity ===
                          interval.assignments[player]?.activity;
                        i++
                      ) {
                        rowSpan++;
                      }

                      return (
                        <td
                          key={`${rowIndex}-${player}`}
                          rowSpan={rowSpan}
                          style={{
                            backgroundColor: interval.assignments[player]?.color || "",
                            color:
                              interval.assignments[player]?.color === "#000000"
                                ? "#ffffff"
                                : "#000000",
                            textAlign: "center",
                          }}
                        >
                          {interval.assignments[player]?.activity || ""}
                        </td>
                      );
                    }

                    return null;
                  }

                  return (
                    <td
                      key={`${rowIndex}-${player}`}
                      style={{
                        backgroundColor: interval.assignments[player]?.color || "",
                        color:
                          interval.assignments[player]?.color === "#000000"
                            ? "#ffffff"
                            : "#000000",
                      }}
                      onDragOver={handleDragOver}
                      onDrop={() => handleDrop(interval.time, player)}
                    >
                      {interval.assignments[player]?.activity || ""}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="actions">
        <button className="back-button" onClick={onGoBack}>
          Back
        </button>
        <button
          className="submit-button"
          onClick={() => onCreateTemplate({ ...formData, timeIntervals })}
        >
          Create Practice Plan
        </button>
      </div>
    </div>
  );
}

export default PracticeScheduleReview;


